import React from 'react';
import { Form } from 'react-final-form';
import { Button } from '../../../../components/Button/Button';
import { ISignInData } from '../../types';
import { EmailField } from '../../../../components/forms/fields/EmailField';
import { PasswordField } from '../../../../components/forms/fields/PasswordField';
import {
  composeValidators,
  createMinLengthStringValidator,
  createRequiredValidator,
} from '../../../../components/forms/validators';
import './styles/SignInForm.scss';

interface ISignInFormProps {
  isLoading?: boolean
  onSubmit: (values: ISignInData) => void
}

export const validatePassword = composeValidators(
  createRequiredValidator('Пароль'),
  createMinLengthStringValidator('пароль', 2),
);

export const SignInForm: React.FC<ISignInFormProps> = ({ isLoading, onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <EmailField
          classes={{
            root: 'sign-in-form__text-field',
          }}
          fullWidth
          disabled={isLoading}
        />
        <PasswordField
          classes={{
            root: 'sign-in-form__text-field',
          }}
          fullWidth
          disabled={isLoading}
          validate={validatePassword}
        />
        <Button
          classes={{
            root: 'sign-in-form__btn',
          }}
          type="submit"
          isLoading={isLoading}
          fullWidth
        >
          Войти
        </Button>
      </form>
    )}
  />
);
