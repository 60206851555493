import {
  all, takeLatest, call, put,
} from 'redux-saga/effects';
import { signInRequest } from '../../services/api';
import { LocalStorageKeys } from '../../constants';
import { history } from '../../routing/history';
import { ROUTES_MAP } from '../../routing/constants';
import {
  signIn, signInError, signInSuccess, signOut,
} from './actions';
import { handleError } from '../../services/sagasErrorHandler';

function* handleSignIn(action: ReturnType<typeof signIn>) {
  try {
    const { data } = yield call(signInRequest, action.payload);
    const { accessToken } = data;

    yield call([localStorage, localStorage.setItem], LocalStorageKeys.accessToken, accessToken);
    yield put(signInSuccess(data));
  } catch (error) {
    yield put(signInError());
    yield call(handleError, error);
  }
}

function* handleSignOut() {
  try {
    yield call([localStorage, localStorage.removeItem], LocalStorageKeys.accessToken);
    yield call(history.push, { pathname: ROUTES_MAP.signIn.path });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* authSagas() {
  yield all([
    yield takeLatest(
      signIn,
      handleSignIn,
    ),
    yield takeLatest(
      signOut,
      handleSignOut,
    ),
  ]);
}
