import React from 'react';
import { TextField } from '../final-forms';
import { ITextFieldProps } from './types';
import { validatePrice } from '../validators';

export const AuctionPriceField: React.FC<ITextFieldProps> = (props) => (
  <TextField
    name="auctionPrice"
    label="Цена на аукционе"
    placeholder="Пожалуйста, введите цену на аукционе"
    type="number"
    validate={validatePrice}
    {...props}
  />
);
