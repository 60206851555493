import React from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { FileUpload } from '../../FileUpload/FileUpload';

export interface IFileUploadFieldProps extends FieldProps<string | undefined, FieldRenderProps<string | undefined>> {
}

export const FileUploadField: React.FC<IFileUploadFieldProps> = (props) => (
  <Field {...props}>
    { ({ input, ...restProps }) => (
      <FileUpload
        value={input.value}
        onChange={input.onChange}
        {...restProps}
      />
    ) }
  </Field>
);
