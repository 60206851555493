import React from 'react';
import { TextField } from '../final-forms';
import { ITextFieldProps } from './types';
import { validatePassword } from '../validators';

export const PasswordField: React.FC<ITextFieldProps> = (props) => (
  <TextField
    name="password"
    label="Password"
    placeholder="Пожалуйста, введите пароль"
    type="password"
    validate={validatePassword}
    {...props}
  />
);
