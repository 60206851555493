export const formatCurrency = (value: number | null, options: Intl.NumberFormatOptions) => {
  const { minimumFractionDigits = 0, ...restOptions } = options || {};

  if (value === null) {
    return null;
  }

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    minimumFractionDigits,
    ...restOptions,
  });

  return formatter.format(value);
};
