import React, { useMemo } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import MaterialUITextField from '@material-ui/core/TextField/TextField';
import { createRequiredValidator } from './validators';

export interface ITextFieldProps extends FieldProps<string | number | undefined, FieldRenderProps<string | number | undefined>> {
  isRequired?: boolean
}

export const TextField: React.FC<ITextFieldProps> = ({ label, isRequired, ...restFieldProps }) => {
  const fieldProps = { ...restFieldProps };
  const validator = useMemo(() => (
    isRequired ? createRequiredValidator(label) : undefined
  ), [label]);

  if (fieldProps.type === 'number' && !fieldProps.parse) {
    fieldProps.parse = (value) => (
      value ? Number(value) : undefined
    );
  }

  return (
    <Field
      label={label}
      validate={validator}
      {...fieldProps}
    >
      { ({ input, meta, ...restProps }) => {
        const errorMessage = meta.touched && (meta.dirty || meta.submitFailed) ? meta.error : '';
        const { onChange, ...inputRestProps } = input;

        return (
          <MaterialUITextField
            error={!!errorMessage}
            helperText={errorMessage}
            onChange={(event) => {
              input.onChange(event.target.value);
            }}
            {...inputRestProps}
            {...restProps}
          />
        );
      } }
    </Field>
  );
};
