import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { ICompletedOrder } from '../types';
import { dialogFormMerge, postCompletedOrder, putCompletedOrder } from '../actions';
import { DialogConfirm, IDialogConfirmProps } from '../../../components/DialogConfirm/DialogConfirm';
import { FileUploadField } from '../../../components/forms/adapters/FileInputField';
import { CompletedOrderTitleField } from '../../../components/forms/fields/CompletedOrderTitleField';
import { AuctionPriceField } from '../../../components/forms/fields/AuctionPriceField';
import { AuctionFeesPriceField } from '../../../components/forms/fields/AuctionFeesPriceField';
import { DeliveryToPortPriceField } from '../../../components/forms/fields/DeliveryToPortPriceField';
import { CompanyServicesPriceField } from '../../../components/forms/fields/CompanyServicesPriceField';
import { UkrainianCustomsPaymentsPriceField } from '../../../components/forms/fields/UkrainianCustomsPaymentsPriceField';
import { UnloadingFromPortPriceField } from '../../../components/forms/fields/UnloadingFromPortPriceField';
import { CustomsBrokerServicesPriceField } from '../../../components/forms/fields/CustomsBrokerServicesPriceField';
import { DeliveryToCityPriceField } from '../../../components/forms/fields/DeliveryToCityPriceField';
import { RepairPriceField } from '../../../components/forms/fields/RepairPriceField';
import { UkrainianMarketPriceField } from '../../../components/forms/fields/UkrainianMarketPriceField';
import './styles/CompletedOrdersFormDialog.scss';

export interface ICompletedOrderFormDialogProps extends Partial<IDialogConfirmProps> {
  initialValues?: Partial<ICompletedOrder>
}

let submitForm = () => {};

export const CompletedOrdersFormDialog: React.FC<ICompletedOrderFormDialogProps> = ({
  initialValues,
  isLoading,
  ...restProps
}) => {
  const isEditFlow = !!initialValues;
  const dispatch = useDispatch();

  const handleFormSubmit = useCallback((values) => {
    if (initialValues?.id) {
      dispatch(putCompletedOrder(values));
    } else {
      dispatch(postCompletedOrder(values));
    }

    dispatch(dialogFormMerge({
      isLoading: true,
    }));
  }, [initialValues]);

  const handleSubmitClick = useCallback(() => {
    submitForm();
  }, [submitForm]);

  return (
    <DialogConfirm
      classes={{
        paper: 'completed-orders-form-dialog__paper',
      }}
      title={`${isEditFlow ? 'Редактировать' : 'Создать'} предложение`}
      isLoading={isLoading}
      onSubmit={handleSubmitClick}
      {...restProps}
    >
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        render={({ handleSubmit }) => {
          submitForm = handleSubmit;

          return (
            <form onSubmit={handleSubmit}>
              <CompletedOrderTitleField
                margin="dense"
                fullWidth
                disabled={isLoading}
              />
              <FileUploadField
                className="completed-orders-form-dialog__file-upload"
                name="wallpaperImg"
                aspectRatio={16 / 9}
              />
              <AuctionPriceField
                margin="dense"
                fullWidth
                disabled={isLoading}
              />
              <AuctionFeesPriceField
                margin="dense"
                fullWidth
                disabled={isLoading}
              />
              <DeliveryToPortPriceField
                margin="dense"
                fullWidth
                disabled={isLoading}
              />
              <CompanyServicesPriceField
                margin="dense"
                fullWidth
                disabled={isLoading}
              />
              <UkrainianCustomsPaymentsPriceField
                margin="dense"
                fullWidth
                disabled={isLoading}
              />
              <UnloadingFromPortPriceField
                margin="dense"
                fullWidth
                disabled={isLoading}
              />
              <CustomsBrokerServicesPriceField
                margin="dense"
                fullWidth
                disabled={isLoading}
              />
              <DeliveryToCityPriceField
                margin="dense"
                fullWidth
                disabled={isLoading}
              />
              <RepairPriceField
                margin="dense"
                fullWidth
                disabled={isLoading}
              />
              <UkrainianMarketPriceField
                margin="dense"
                fullWidth
                disabled={isLoading}
              />
            </form>
          );
        }}
      />
    </DialogConfirm>
  );
};
