import { createReducer } from '@reduxjs/toolkit';
import {
  signIn, signInError, signInSuccess, signOut,
} from './actions';
import { LocalStorageKeys } from '../../constants';
import { IAuthReducer } from './types';

const initialState: IAuthReducer = {
  isLoading: false,
  accessToken: localStorage.getItem(LocalStorageKeys.accessToken),
};

export const authReducer = createReducer(initialState, (builder) => {
  builder.addCase(signIn, (state) => ({
    ...state,
    isLoading: true,
  }));

  builder.addCase(signInSuccess, (state, { payload }) => ({
    ...state,
    isLoading: false,
    accessToken: payload.accessToken,
  }));

  builder.addCase(signInError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(signOut, () => ({
    ...initialState,
    accessToken: null,
  }));
});
