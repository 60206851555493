import React from 'react';
import classNames from 'classnames';
import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress } from '../../../components/CircularProgress/CircularProgress';
import { CompletedOrderId, ICompletedOrders } from '../types';
import { OfferCard } from '../../../components/OfferCard/OfferCard';
import { getAPIFileURLByName } from '../../../services/api/helpers';
import './styles/CompletedOrdersList.scss';

interface ICompletedOrdersListProps {
  items: ICompletedOrders
  currency: string
  syncingIds: CompletedOrderId[]
  onEdit: (id: CompletedOrderId) => void
  onRemove: (id: CompletedOrderId) => void
}

export const CompletedOrdersList: React.FC<ICompletedOrdersListProps> = ({
  items, currency, syncingIds, onEdit, onRemove,
}) => (
  <div className="completed-orders-list">
    { items.map(({
      id, wallpaperImg, ...restProps
    }) => {
      const isSyncing = syncingIds.indexOf(id) !== -1;
      const imgSrc = getAPIFileURLByName(wallpaperImg);

      return (
        <div className="completed-orders-list__item" key={id}>
          <OfferCard
            id={id}
            wallpaperImg={imgSrc}
            currency={currency}
            {...restProps}
          />
          <div className="completed-orders-list__item-controls">
            <div>
              <IconButton onClick={() => onEdit(id)}>
                <IconEdit />
              </IconButton>
              <IconButton onClick={() => onRemove(id)}>
                <IconDelete />
              </IconButton>
            </div>
            <CircularProgress
              className={
                classNames('completed-orders-list__loading-indicator', {
                  'completed-orders-list__loading-indicator--visible': isSyncing,
                })
              }
              size={35}
            />
          </div>
        </div>
      );
    }) }
  </div>
);
