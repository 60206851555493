import React from 'react';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { Button } from '../Button/Button';
import './styles/EmptyState.scss';

interface IEmptyStateProps {
  onRefresh?: () => void
}

export const EmptyState: React.FC<IEmptyStateProps> = ({ onRefresh }) => (
  <div className="empty-state">
    <SentimentVeryDissatisfiedIcon className="empty-state__icon" />
    <h3 className="empty-state__title">
      Ой! Похоже, у нас нет данных для отображения :( &#10;
      Пожалуйста, попробуйте добавить новые элементы.
    </h3>
    { onRefresh && (
      <Button
        className="empty-state__refresh-btn"
        onClick={onRefresh}
      >
        Обновить
      </Button>
    ) }
  </div>
);
