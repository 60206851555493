import React from 'react';
import { TextField } from '../final-forms';
import { ITextFieldProps } from './types';
import { validatePrice } from '../validators';

export const DeliveryToPortPriceField: React.FC<ITextFieldProps> = (props) => (
  <TextField
    name="deliveryToPortPrice"
    label="Цена доставки в порт Одессы"
    placeholder="Пожалуйста, введите цену доставки в порт Одессы"
    type="number"
    validate={validatePrice}
    {...props}
  />
);
