import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import IconButton from '@material-ui/core/IconButton';
import IconDelete from '@material-ui/icons/Delete';
import { fileUploadRequest, fileRemoveRequest } from '../../services/api';
import { FilesInput } from '../FilesInput/FilesInput';
import { LoadingOverlay } from '../loadingOverlay/LoadingOverlay';
import { getAPIFileURLByName } from '../../services/api/helpers';
import './FileUpload.scss';

export interface IFileUploadProps {
  className?: string
  value?: string
  aspectRatio?: number
  onChange: (value?: string | null) => void
}

export const FileUpload: React.FC<IFileUploadProps> = ({
  className, value, aspectRatio, onChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = useCallback((files: FileList) => {
    setIsLoading(true);

    fileUploadRequest(files[0]).then((result) => {
      onChange(result.filename);

      setIsLoading(false);
    }).catch((error) => {
      toast.error(error.message);

      setIsLoading(false);
    });
  }, [onChange]);

  const handleRemove = useCallback(() => {
    if (value) {
      try {
        fileRemoveRequest(value);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    onChange(null);
  }, [value, onChange]);

  return (
    <FilesInput
      className={className}
      aspectRatio={aspectRatio}
      multiple={false}
      onChange={handleFileUpload}
    >
      <LoadingOverlay isVisible={isLoading} />
      { value && (
        <>
          <img className="file-upload__img" src={getAPIFileURLByName(value)} alt="" />
          <IconButton
            className="file-upload__btn-remove"
            size="small"
            onClick={handleRemove}
          >
            <IconDelete className="file-upload__btn-remove-icon" />
          </IconButton>
        </>
      ) }
    </FilesInput>
  );
};
