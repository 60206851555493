import React, { useCallback, useState } from 'react';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
import { ICompletedOrder } from '../../modules/completedOrders/types';
import { formatCurrency } from '../../services/dataFormat';
import './styles/OfferCard.scss';

export interface IOfferCardProps extends ICompletedOrder {
  className?: string
  currency: string
}

export const OfferCard: React.FC<IOfferCardProps> = ({
  className,
  wallpaperImg,
  title,
  auctionPrice,
  auctionFeesPrice,
  deliveryToPortPrice,
  companyServicesPrice,
  ukrainianCustomsPaymentsPrice,
  unloadingFromPortPrice,
  customsBrokerServicesPrice,
  deliveryToCityPrice,
  repairPrice,
  ukrainianMarketPrice,
  currency,
}) => {
  const [isRotated, setIsRotated] = useState(false);

  const handleShowFront = useCallback(() => {
    setIsRotated(false);
  }, [setIsRotated]);

  const handleShowBackward = useCallback(() => {
    setIsRotated(true);
  }, [setIsRotated]);

  const summaryPrice = (
    auctionPrice
    + auctionFeesPrice
    + deliveryToPortPrice
    + companyServicesPrice
    + ukrainianCustomsPaymentsPrice
    + unloadingFromPortPrice
    + customsBrokerServicesPrice
    + deliveryToCityPrice
    + repairPrice
  );

  const benefit = Math.ceil(100 - ((summaryPrice / ukrainianMarketPrice) * 100));

  const formatPrice = useCallback((price: number) => (
    formatCurrency(price, { currency })
  ), []);

  const renderPriceListItem = useCallback((itemTitle: string, price: number) => (
    <div className="offer-card__price-item">
      <div className="offer-card__price-title">
        { itemTitle }
      </div>
      <div className="offer-card__price-value">
        { formatPrice(price) }
      </div>
    </div>
  ), []);

  const renderBackwardContent = useCallback(() => (
    <>
      <div className="offer-card__title">
        { title }
      </div>
      <div className="offer-card__price">
        { renderPriceListItem('Цена авто на аукционе', auctionPrice) }
        { renderPriceListItem('Сбор аукциона', auctionFeesPrice) }
        { renderPriceListItem('Доставка в порт Одессы', deliveryToPortPrice) }
        { renderPriceListItem('Услуги компании AutoImport', companyServicesPrice) }
        { renderPriceListItem('Таможенные платежи', ukrainianCustomsPaymentsPrice) }
        { renderPriceListItem('Выгрузка авто в порту Одессы', unloadingFromPortPrice) }
        { renderPriceListItem('Услуги таможенного брокера', customsBrokerServicesPrice) }
        { renderPriceListItem('Доставка авто в ваш город', deliveryToCityPrice) }
        { renderPriceListItem('Затраты на ремонт', repairPrice) }
      </div>
      <div className="offer-card__ukrainian-price">
        { formatPrice(ukrainianMarketPrice) }
      </div>
      <div className="offer-card__details">
        <div className="offer-card__summary-price">
          { formatPrice(summaryPrice) }
        </div>
        <Button
          color="default"
          onClick={handleShowFront}
        >
          Скрыть
        </Button>
      </div>
    </>
  ), [
    auctionPrice,
    auctionFeesPrice,
    deliveryToPortPrice,
    companyServicesPrice,
    ukrainianCustomsPaymentsPrice,
    unloadingFromPortPrice,
    customsBrokerServicesPrice,
    deliveryToCityPrice,
    repairPrice,
    ukrainianMarketPrice,
  ]);

  return (
    <Card
      className={className}
      isRotated={isRotated}
      renderBackwardContent={renderBackwardContent}
    >
      <div className="offer-card__wallpaper-container">
        <img
          className="offer-card__wallpaper-img"
          src={wallpaperImg}
          alt="Wallpaper"
        />
        <div className="offer-card__badge">
          { benefit }
          %
        </div>
      </div>
      <div className="offer-card__title">{ title }</div>
      <div className="offer-card__ukrainian-price">
        { formatPrice(ukrainianMarketPrice) }
      </div>
      <div className="offer-card__details">
        <div className="offer-card__summary-price">
          { formatPrice(summaryPrice) }
        </div>
        <Button
          color="default"
          onClick={handleShowBackward}
        >
          Подробнее
        </Button>
      </div>
    </Card>
  );
};
