import React from 'react';
import { TextField } from '../final-forms';
import { ITextFieldProps } from './types';
import {
  composeValidators,
  createMinLengthStringValidator,
  createRequiredValidator,
} from '../validators';

export const validate = composeValidators(
  createRequiredValidator('Название предложения'),
  createMinLengthStringValidator('название предложения', 3),
);

export const CompletedOrderTitleField: React.FC<ITextFieldProps> = (props) => (
  <TextField
    name="title"
    label="Название предложения"
    placeholder="Пожалуйста, введите название предложения"
    validate={validate}
    {...props}
  />
);
