import React from 'react';
import { RouteProps } from 'react-router-dom';

export const withPermissions = (predicate: () => boolean, renderFallback: (props: RouteProps) => React.ReactElement) => (
  // TODO: any type should be removed
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> => (props: any) => {
    if (!predicate()) {
      return renderFallback(props);
    }

    return (
      <Component {...props} />
    );
  }
);
