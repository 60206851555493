import React from 'react';
import {
  Route, Switch as ReactRouterDomSwitch, Redirect,
} from 'react-router-dom';
import { ROUTES_MAP, DEFAULT_ROUTE } from './constants';
import { SignIn } from '../modules/auth/components/SignIn';
import { CompletedOrders } from '../modules/completedOrders/components/CompletedOrders';
import { withPermissions } from '../hocs/withPermissions';
import { isAuthenticated } from '../services/auth';

const PrivateRoute = withPermissions(
  isAuthenticated,
  (props) => {
    const { location } = props;

    return (
      <Redirect
        to={{
          pathname: ROUTES_MAP.signIn.path,
          state: {
            referrer: location,
          },
        }}
      />
    );
  },
)(Route);

export const Switch = () => (
  <ReactRouterDomSwitch>
    <Route
      {...ROUTES_MAP.signIn}
      component={SignIn}
    />
    <PrivateRoute
      {...ROUTES_MAP.completedOrders}
      component={CompletedOrders}
    />
    <Redirect to={DEFAULT_ROUTE.path} />
  </ReactRouterDomSwitch>
);
