import React from 'react';
import { TextField } from '../final-forms';
import { ITextFieldProps } from './types';
import { validatePrice } from '../validators';

export const CustomsBrokerServicesPriceField: React.FC<ITextFieldProps> = (props) => (
  <TextField
    name="customsBrokerServicesPrice"
    label="Услуга таможенного брокера"
    placeholder="Пожалуйста, введите цену на услуги таможенного брокера"
    type="number"
    validate={validatePrice}
    {...props}
  />
);
