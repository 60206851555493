import React from 'react';
import { IOverlayProps, Overlay } from '../Overlay/Overlay';
import { CircularProgress } from '../CircularProgress/CircularProgress';

interface ILoadingOverlayProps extends IOverlayProps {
}

export const LoadingOverlay: React.FC<ILoadingOverlayProps> = (props) => (
  <Overlay {...props}>
    <CircularProgress size={50} />
  </Overlay>
);
