import React from 'react';
import classNames from 'classnames';
import './styles/Overlay.scss';

export interface IOverlayProps {
  className?: string,
  isVisible?: boolean
  onClick?: () => void,
}

export const Overlay: React.FC<IOverlayProps> = ({
  className,
  isVisible,
  children,
  onClick,
}) => (
  <div className={classNames(
    'overlay',
    { 'overlay--is-visible': isVisible },
  )}
  >
    <div
      className={classNames(
        'overlay__backdrop',
        className,
      )}
      role="presentation"
      onClick={onClick}
    />
    {children && <div className="overlay__content">{children}</div>}
  </div>
);
