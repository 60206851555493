// import 'react-app-polyfill/ie11'; // TODO: Please uncomment it if you need IE11 to be supported
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { history } from './routing/history';
import { Switch } from './routing/switch';
import { store } from './store/store';
import { App } from './modules/app/components/App';
import { LoadingOverlay } from './components/loaders/LoadingOverlay/LoadingOverlay';
import 'react-toastify/dist/ReactToastify.css';
import './styles/app.scss';

toast.configure();

const AppRoot = () => (
  <StylesProvider injectFirst>
    <Suspense fallback={<LoadingOverlay />}>
      <Provider store={store}>
        <Router history={history}>
          <App>
            <Switch />
          </App>
        </Router>
      </Provider>
    </Suspense>
  </StylesProvider>
);

const render = () => {
  ReactDOM.render(
    <AppRoot />,
    document.getElementById('root'),
  );
};

render();

if (module.hot) {
  module.hot.accept('./index.tsx', () => {
    render();
  });
}
