export interface IRoutesMap {
  path: string
  exact?: boolean
  isWithoutSidebar?: boolean
}

export const ROUTES_MAP = {
  signIn: {
    path: '/sign-in', exact: true, isWithoutSidebar: true,
  },
  completedOrders: {
    path: '/completed-orders', exact: true,
  },
};

export const DEFAULT_ROUTE = ROUTES_MAP.completedOrders;
