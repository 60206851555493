import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import IconOrder from '@material-ui/icons/ViewModule';
import { ROUTES_MAP } from '../../../routing/constants';
import { Button } from '../../../components/Button/Button';
import { signOut } from '../../auth/actions';
import './styles/Sidebar.scss';

const SIDEBAR_ITEMS = [
  {
    title: 'Топ-предложения',
    path: ROUTES_MAP.completedOrders.path,
    Icon: IconOrder,
  },
];

interface ISidebarProps {
  className?: string
}

export const Sidebar: React.FC<ISidebarProps> = ({ className }) => {
  const dispatch = useDispatch();

  const handleSignOut = useCallback(() => {
    dispatch(signOut());
  }, []);

  return (
    <div className={classNames('sidebar', className)}>
      { SIDEBAR_ITEMS.map(({ title, path, Icon }) => (
        <NavLink
          key={`${title}_${path}`}
          className="sidebar__item"
          activeClassName="sidebar__item--active"
          to={path}
        >
          <Icon className="sidebar__item-icon" />
          { title }
        </NavLink>
      )) }

      <div className="sidebar__controls">
        <Button
          className="sidebar__sign-out-button"
          fullWidth
          onClick={handleSignOut}
        >
          Выйти
        </Button>
      </div>
    </div>
  );
};
