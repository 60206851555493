import { createAction } from '@reduxjs/toolkit';
import {
  CompletedOrderId, ICompletedOrder, ICompletedOrders, IPostCompletedOrderRequestBody, IPutCompletedOrderRequestBody,
} from './types';
import { ICompletedOrderFormDialogProps } from './components/CompletedOrdersFormDialog';

export const postCompletedOrder = createAction<IPostCompletedOrderRequestBody>('COMPLETED_ORDERS/POST_COMPLETED_ORDER');
export const postCompletedOrderSuccess = createAction<ICompletedOrder>('COMPLETED_ORDERS/POST_COMPLETED_ORDER/SUCCESS');

export const getCompletedOrders = createAction('COMPLETED_ORDERS/GET_COMPLETED_ORDERS');
export const getCompletedOrdersSuccess = createAction<ICompletedOrders>('COMPLETED_ORDERS/GET_COMPLETED_ORDERS/SUCCESS');
export const getCompletedOrdersError = createAction('COMPLETED_ORDERS/GET_COMPLETED_ORDERS_ERROR');

export const putCompletedOrder = createAction<IPutCompletedOrderRequestBody>('COMPLETED_ORDERS/PUT_COMPLETED_ORDER');
export const putCompletedOrderSuccess = createAction<ICompletedOrder>('COMPLETED_ORDERS/PUT_COMPLETED_ORDER/SUCCESS');

export const removeCompletedOrder = createAction<CompletedOrderId>('COMPLETED_ORDERS/REMOVE_COMPLETED_ORDER');
export const removeCompletedOrderSuccess = createAction<CompletedOrderId>('COMPLETED_ORDERS/REMOVE_COMPLETED_ORDER/SUCCESS');
export const removeCompletedOrderError = createAction<CompletedOrderId>('COMPLETED_ORDERS/REMOVE_COMPLETED_ORDER_ERROR');

export const dialogFormSet = createAction<Partial<ICompletedOrderFormDialogProps> | undefined>('COMPLETED_ORDERS/DIALOG_FORM_SET');
export const dialogFormMerge = createAction<Partial<ICompletedOrderFormDialogProps>>('COMPLETED_ORDERS/DIALOG_FORM_MERGE');
