import React, { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { IRoutesMap, ROUTES_MAP } from '../../../routing/constants';
import './styles/App.scss';

const ROUTES_WITHOUT_SIDEBAR: IRoutesMap[] = Object.values(ROUTES_MAP).filter((route: IRoutesMap) => route.isWithoutSidebar);

export interface IAppProps {
}

export const App: React.FC<IAppProps> = ({ children }) => {
  const { pathname } = useLocation();

  const isWithSidebar = useMemo(() => (
    !ROUTES_WITHOUT_SIDEBAR.some((route) => matchPath(pathname, route))
  ), [pathname]);

  return (
    <div className="app">
      <div className="app__main-container">
        { isWithSidebar && (
          <Sidebar className="app__sidebar" />
        ) }
        <main className="app__content">
          { children }
        </main>
      </div>
    </div>
  );
};
