import React, { useCallback } from 'react';
import classNames from 'classnames';
import IconUpload from '@material-ui/icons/Backup';
import './FilesInput.scss';

interface IFilesInput extends Partial<Omit<HTMLInputElement, 'children'>> {
  aspectRatio?: number
  children: React.ReactNode
  onChange: (files: FileList) => void
}

export const FilesInput: React.FC<IFilesInput> = ({
  className,
  aspectRatio = 1,
  accept = 'image/jpeg, image/png',
  multiple = false,
  children,
  onChange,
}) => {
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files) {
      onChange(files);
    }

    // ignored cause we need to clean up file
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  }, [onChange]);

  return (
    <div
      className={classNames('files-input', className)}
      style={{
        paddingTop: `${(1 / aspectRatio) * 100}%`,
      }}
    >
      <div className="files-input__container">
        <IconUpload className="files-input__icon" />
      </div>
      <input
        className="files-input__input"
        type="file"
        accept={accept}
        multiple={multiple}
        onChange={handleChange}
      />
      { children }
    </div>
  );
};
