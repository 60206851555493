import { FieldValidator } from 'final-form';

const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
const PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&`]).{8,}$/;

const PASSWORD_ERROR_MESSAGE = `Пароль должен соответствовать следующим требованиям: минимум восемь символов, минимум один
     прописная буква, одна строчная буква, одна цифра и один специальный символ`;

export const composeValidators = (...validators: FieldValidator<string>[]) => (value: any, allValues: any) => {
  let error;

  validators.some((validator) => {
    error = validator(value, allValues);

    return error;
  });

  return error;
};

export const createRequiredValidator = (label: string) => (value: any) => (
  value ? undefined : `${label} обязательно нужно указать`
);

export const createMinLengthStringValidator = (label: string, length: number) => (value: any) => (
  (value || '').trim().length >= length ? undefined : `В поле ${label} надо ввести как минимум ${length} символа`
);

export const createMinLengthNumberValidator = (label: string, length: number) => (value: any) => (
  value.toString().length >= length ? undefined : `В поле ${label} надо ввести  как минимум ${length} символа`
);

export const validateEmail = (value: any) => (
  EMAIL_REGEXP.test(value || '') ? undefined : 'Пожалуйста, убедитесь, что адрес электронной почты указан правильно'
);

export const validatePassword = (value: any) => (
  PASSWORD_REGEXP.test(value || '') ? undefined : PASSWORD_ERROR_MESSAGE
);

export const validatePrice = composeValidators(
  createRequiredValidator('Цену'),
  createMinLengthNumberValidator('цена', 2),
);
