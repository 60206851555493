import { all } from 'redux-saga/effects';
import { authSagas } from '../modules/auth/sagas';
import { completedOrdersSaga } from '../modules/completedOrders/sagas';

export function* rootSaga() {
  yield all([
    authSagas(),
    completedOrdersSaga(),
  ]);
}
