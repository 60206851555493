import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_FILES_BASE_URL, LocalStorageKeys } from '../../constants';
import { IAPIErrorResponseBody } from './types';
import { API_HOST } from '../../../../autoimport.client.fe/constants/common';

export const getURL = (path: string) => `${API_HOST}/api${path}`;

export const preprocessResponse = (response: Response) => {
  const { status } = response;

  return response.json().then((result) => {
    if (status !== 200 && status !== 201) {
      throw new Error(result.message);
    }

    return result;
  });
};

export const processApiResponseError = (
  error: AxiosError<IAPIErrorResponseBody>,
  defaultErrorMessage = 'Упс! Что-то пошло не так. Пожалуйста, попробуйте немного позже.',
  additionMessage = '',
) => {
  const { response } = error;
  let errorMessage = response?.data?.message || defaultErrorMessage;

  if (additionMessage) {
    errorMessage += `\n\n${additionMessage}`;
  }

  // eslint-disable-next-line no-param-reassign
  error.message = errorMessage;

  throw error;
};

type MakeRequest = <T>(url: string, data?: any, method?: 'POST' | 'GET' | 'PUT' | 'DELETE', headers?: Headers) => Promise<AxiosResponse<T>>;

export const makeRequest: MakeRequest = (url, data, method = 'GET', headers = undefined) => {
  const isFormData = data instanceof FormData;

  const requestHeaders = {
    accept: 'application/json',
    'Content-Type': isFormData ? 'multipart/form-data' : 'application/json;charset=utf-8',
    Authorization: `Bearer ${localStorage.getItem(LocalStorageKeys.accessToken)}`,
    ...headers,
  };

  const requestConfig = {
    headers: requestHeaders,
    method,
    data: isFormData ? data : JSON.stringify(data),
  };

  return axios(url, requestConfig)
    .catch(processApiResponseError);
};

export const makeFileUploadRequest = (
  url: string,
  data: FormData,
): Promise<{ filename: string }> => fetch(url, {
  method: 'POST',
  body: data,
}).then(preprocessResponse);

export const getAPIFileURLByName = (filename: string) => `${API_FILES_BASE_URL}/${filename}`;
