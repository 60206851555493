import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import IconAdd from '@material-ui/icons/Add';
import { CompletedOrdersList } from './CompletedOrdersList';
import { dialogFormSet, getCompletedOrders, removeCompletedOrder } from '../actions';
import { CompletedOrdersFormDialog } from './CompletedOrdersFormDialog';
import { LoadingOverlay } from '../../../components/loadingOverlay/LoadingOverlay';
import { EmptyState } from '../../../components/emptyState/EmptyState';
import {
  CompletedOrderId, ICompletedOrder, ICompletedOrdersReducer, ICompletedOrdersStoreSlice,
} from '../types';
import { CURRENCY } from '../../../constants';
import './styles/CompletedOrders.scss';

export const CompletedOrders = () => {
  const {
    isLoading, dialogForm, completedOrders, syncingIds,
  } = useSelector<ICompletedOrdersStoreSlice, ICompletedOrdersReducer>((state) => state.completedOrdersReducer);
  const dispatch = useDispatch();

  const handleGetCompletedOrders = useCallback(() => {
    dispatch(getCompletedOrders());
  }, []);

  const handleCompletedOrderRemove = useCallback((completedOrderId: CompletedOrderId) => {
    dispatch(removeCompletedOrder(completedOrderId));
  }, []);

  const handleOpenDialogForm = useCallback((initialValues?: Partial<ICompletedOrder>) => {
    dispatch(dialogFormSet({
      open: true,
      initialValues,
    }));
  }, []);

  const handleOpenCreateDialogForm = useCallback(() => {
    handleOpenDialogForm();
  }, [handleOpenDialogForm]);

  const handleCloseDialogForm = useCallback(() => {
    dispatch(dialogFormSet());
  }, []);

  const handleCompletedOrderEdit = useCallback((id: string) => {
    const order = completedOrders.filter((item) => item.id === id)[0];

    handleOpenDialogForm(order);
  }, [completedOrders, handleOpenDialogForm]);

  useEffect(() => {
    handleGetCompletedOrders();
  }, []);

  return (
    <div className="completed-orders">
      { completedOrders.length ? (
        <CompletedOrdersList
          items={completedOrders}
          currency={CURRENCY}
          syncingIds={syncingIds}
          onEdit={handleCompletedOrderEdit}
          onRemove={handleCompletedOrderRemove}
        />
      ) : !isLoading && (
        <EmptyState onRefresh={handleGetCompletedOrders} />
      ) }
      <CompletedOrdersFormDialog
        {...dialogForm}
        onClose={handleCloseDialogForm}
      />
      <Fab
        className="completed-orders__fab-btn"
        color="primary"
        onClick={handleOpenCreateDialogForm}
      >
        <IconAdd />
      </Fab>
      <LoadingOverlay isVisible={isLoading} />
    </div>
  );
};
