import { createReducer } from '@reduxjs/toolkit';
import {
  dialogFormMerge,
  dialogFormSet,
  getCompletedOrders,
  getCompletedOrdersError,
  getCompletedOrdersSuccess,
  postCompletedOrderSuccess,
  putCompletedOrderSuccess,
  removeCompletedOrder,
  removeCompletedOrderError,
  removeCompletedOrderSuccess,
} from './actions';
import { ICompletedOrdersReducer } from './types';

const initialState: ICompletedOrdersReducer = {
  isLoading: false,
  dialogForm: {
    open: false,
    isLoading: false,
    initialValues: {},
  },
  completedOrders: [],
  syncingIds: [],
};

export const completedOrdersReducer = createReducer(initialState, (builder) => {
  builder.addCase(postCompletedOrderSuccess, (state, { payload }) => ({
    ...state,
    completedOrders: [
      payload,
      ...state.completedOrders,
    ],
  }));

  builder.addCase(getCompletedOrders, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getCompletedOrdersSuccess, (state, { payload }) => ({
    ...state,
    isLoading: false,
    completedOrders: payload,
  }));
  builder.addCase(getCompletedOrdersError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(putCompletedOrderSuccess, (state, { payload }) => ({
    ...state,
    completedOrders: state.completedOrders.map((item) => {
      if (item.id !== payload.id) {
        return item;
      }

      return {
        ...item,
        ...payload,
      };
    }),
  }));

  builder.addCase(removeCompletedOrder, (state, { payload }) => ({
    ...state,
    syncingIds: [...state.syncingIds, payload],
  }));
  builder.addCase(removeCompletedOrderSuccess, (state, { payload }) => ({
    ...state,
    completedOrders: state.completedOrders.filter((order) => order.id !== payload),
    syncingIds: state.syncingIds.filter((id) => id !== payload),
  }));
  builder.addCase(removeCompletedOrderError, (state, { payload }) => ({
    ...state,
    syncingIds: state.syncingIds.filter((id) => id !== payload),
  }));

  builder.addCase(dialogFormSet, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...initialState.dialogForm,
      ...payload,
    },
  }));

  builder.addCase(dialogFormMerge, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...state.dialogForm,
      ...payload,
    },
  }));
});
