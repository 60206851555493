export const API_HOST = process.env.API_HOST;
export const API_FILES_HOST = `${API_HOST}/api/files`;

export const PRIMARY_CONTACT_PHONE_NUMBER = "+38 (050) 194 95 87";
export const SECONDARY_CONTACT_PHONE_NUMBER = "+38 (066) 892 47 62";
export const ADDRESS = 'г.Полтава, ул. Европейская, 68';

export const TITLE = 'Авто из США купить в Полтаве | Машины из Америки по цене ниже рыночной';
export const DESCRIPTION = `Покупка (Copart, IAAI, Manheim), логистика, доставка авто, мототехники из США. Наш адрес: Украина, ${ADDRESS}`;

export enum SocialMediaLink {
  instagram = 'https://www.instagram.com/auto_import.ua/',
  linkedin = '',
  facebook = '',
}

export const MAP_LINK = 'https://www.google.com/maps/place/%D1%83%D0%BB.+%D0%95%D0%B2%D1%80%D0%BE%D0%BF%D0%B5%D0%B9%D1%81%D0%BA%D0%B0%D1%8F,+68,+%D0%9F%D0%BE%D0%BB%D1%82%D0%B0%D0%B2%D0%B0,+%D0%9F%D0%BE%D0%BB%D1%82%D0%B0%D0%B2%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+36000/@49.574197,34.535582,16z/data=!4m5!3m4!1s0x40d82f43a72557b3:0xb12ea7a4fc006295!8m2!3d49.5741973!4d34.5355819?hl=ru';

export const MIN_BUDGET = 8000;
export const MAX_BUDGET = 100000;
export const BUDGET_SLIDER_STEP = 100;

export const CURRENCY = 'USD';
