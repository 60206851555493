import { getURL, makeFileUploadRequest, makeRequest } from './helpers';
import { ISignInData } from '../../modules/auth/types';

export const signInRequest = (data: ISignInData) => makeRequest(getURL('/auth/sign-in'), data, 'POST');

export const fileRemoveRequest = (fileName: string) => (
  makeRequest(getURL(`/files/${fileName}`), undefined, 'DELETE')
);

export const fileUploadRequest = (file: File) => {
  const formData = new FormData();

  formData.append('file', file);

  return makeFileUploadRequest(getURL('/files'), formData);
};
