import React from 'react';
import { TextField } from '../final-forms';
import { ITextFieldProps } from './types';
import { validatePrice } from '../validators';

export const RepairPriceField: React.FC<ITextFieldProps> = (props) => (
  <TextField
    name="repairPrice"
    label="Затраты на ремонт"
    placeholder="Пожалуйста, введите цену на услуги по ремноту"
    type="number"
    validate={validatePrice}
    {...props}
  />
);
