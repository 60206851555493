import { getURL, makeRequest } from '../../services/api/helpers';
import {
  CompletedOrderId, ICompletedOrders, IPutCompletedOrderRequestBody, IPostCompletedOrderRequestBody,
} from './types';

export const postCompletedOrderRequest = (data: IPostCompletedOrderRequestBody) => (
  makeRequest(getURL('/orders'), data, 'POST')
);

export const getCompletedOrdersRequest = () => (
  makeRequest<ICompletedOrders>(getURL('/orders'))
);

export const putCompletedOrderRequest = (data: IPutCompletedOrderRequestBody) => (
  makeRequest(getURL(`/orders/${data.id}`), data, 'PUT')
);

export const removeCompletedOrderRequest = (id: CompletedOrderId) => (
  makeRequest(getURL(`/orders/${id}`), undefined, 'DELETE')
);
