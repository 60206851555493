import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { SignInForm } from './forms/SignInForm';
import { signIn } from '../actions';
import { ISignInData } from '../types';
import { DEFAULT_ROUTE } from '../../../routing/constants';
import { authReducerSelector } from '../selectors';
import './styles/SignIn.scss';

interface ISignInProps extends RouteComponentProps<any, any, { referrer?: string }> {
}

export const SignIn: React.FC<ISignInProps> = ({ location }) => {
  const referrerFallbackLocation = location.state?.referrer;
  const { isLoading, accessToken } = useSelector(authReducerSelector);
  const dispatch = useDispatch();

  const handleSubmit = useCallback((values: ISignInData) => {
    dispatch(signIn(values));
  }, []);

  if (accessToken) {
    return (
      <Redirect to={referrerFallbackLocation || DEFAULT_ROUTE.path} />
    );
  }

  return (
    <div className="sign-in">
      <div className="sign-in__wallpaper" />
      <div className="sign-in__container">
        <div className="sign-in__content">
          <SignInForm isLoading={isLoading} onSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};
