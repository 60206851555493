import React from 'react';
import classNames from 'classnames';
import './styles/Card.scss';

export interface ICardProps {
  className?: string
  classNameFrontContainer?: string
  classNameBackContainer?: string
  isRotated?: boolean
  renderBackwardContent?: () => React.ReactNode
}

export const Card: React.FC<ICardProps> = ({
  className,
  classNameFrontContainer,
  classNameBackContainer,
  isRotated,
  children,
  renderBackwardContent,
}) => (
  <div className={classNames(
    'card', {
      'card-rotated': isRotated,
    },
    className,
  )}
  >
    <div className={classNames('card__front-container', classNameFrontContainer)}>
      { children }
    </div>
    { renderBackwardContent && (
      <div className={classNames('card__back-container', classNameBackContainer)}>
        { renderBackwardContent() }
      </div>
    ) }
  </div>
);
