import React from 'react';
import classNames from 'classnames';
import MaterialUIButton, { ButtonProps as MaterialUIButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Button.scss';

interface IButtonProps extends MaterialUIButtonProps {
  isLoading?: boolean
}

export const Button: React.FC<IButtonProps> = ({
  className,
  isLoading,
  disabled,
  children,
  variant = 'contained',
  color = 'primary',
  ...restProps
}) => (
  <MaterialUIButton
    {...restProps}
    className={classNames(
      'button',
      className,
    )}
    variant={variant}
    color={color}
    disabled={isLoading || disabled}
  >
    { children }
    { isLoading && (
      <div className="button__spinner">
        <CircularProgress color="primary" size={25} />
      </div>
    ) }
  </MaterialUIButton>
);
