import React from 'react';
import { TextField } from '../final-forms';
import { ITextFieldProps } from './types';
import { validateEmail } from '../validators';

export const EmailField: React.FC<ITextFieldProps> = (props) => (
  <TextField
    name="username"
    label="Email"
    placeholder="Пожалуйста, укажите свой адрес электронной почты"
    validate={validateEmail}
    {...props}
  />
);
