import React from 'react';
import MUICircularProgress from '@material-ui/core/CircularProgress';
import { CircularProgressProps } from '@material-ui/core/CircularProgress/CircularProgress';

interface ICircularProgressProps extends CircularProgressProps {
  classNameSpinner?: string
}

export const CircularProgress: React.FC<ICircularProgressProps> = ({
  className,
  classNameSpinner,
  ...restProps
}) => (
  <div className={className}>
    <MUICircularProgress
      className={classNameSpinner}
      {...restProps}
    />
  </div>
);
