import {
  all, takeLatest, takeEvery, call, put,
} from 'redux-saga/effects';
import {
  getCompletedOrdersRequest, postCompletedOrderRequest, putCompletedOrderRequest, removeCompletedOrderRequest,
} from './api';
import {
  dialogFormMerge,
  dialogFormSet,
  postCompletedOrderSuccess,
  getCompletedOrdersError,
  getCompletedOrdersSuccess,
  putCompletedOrderSuccess,
  removeCompletedOrderError,
  removeCompletedOrderSuccess,
  postCompletedOrder,
  removeCompletedOrder,
  putCompletedOrder,
  getCompletedOrders,
} from './actions';
import { handleError } from '../../services/sagasErrorHandler';

function* handlePostCompletedOrder(action: ReturnType<typeof postCompletedOrder>) {
  try {
    const { data } = yield call(postCompletedOrderRequest, action.payload);

    yield put(postCompletedOrderSuccess(data));
    yield put(dialogFormSet());
  } catch (error) {
    yield put(dialogFormMerge({
      isLoading: false,
    }));
    yield call(handleError, error);
  }
}

function* handleGetCompletedOrders() {
  try {
    const { data } = yield call(getCompletedOrdersRequest);

    yield put(getCompletedOrdersSuccess(data));
  } catch (error) {
    yield put(getCompletedOrdersError());
    yield call(handleError, error);
  }
}

function* handlePutCompletedOrder(action: ReturnType<typeof putCompletedOrder>) {
  try {
    const { data } = yield call(putCompletedOrderRequest, action.payload);

    yield put(putCompletedOrderSuccess(data));
    yield put(dialogFormSet());
  } catch (error) {
    yield put(dialogFormMerge({
      isLoading: false,
    }));
    yield call(handleError, error);
  }
}

function* handleRemoveCompletedOrder({ payload }: ReturnType<typeof removeCompletedOrder>) {
  try {
    yield call(removeCompletedOrderRequest, payload);
    yield put(removeCompletedOrderSuccess(payload));
  } catch (error) {
    yield put(removeCompletedOrderError(payload));
    yield call(handleError, error);
  }
}

export function* completedOrdersSaga() {
  yield all([
    yield takeEvery(
      postCompletedOrder,
      handlePostCompletedOrder,
    ),
    yield takeLatest(
      getCompletedOrders,
      handleGetCompletedOrders,
    ),
    yield takeEvery(
      putCompletedOrder,
      handlePutCompletedOrder,
    ),
    yield takeEvery(
      removeCompletedOrder,
      handleRemoveCompletedOrder,
    ),
  ]);
}
