import React from 'react';
import { TextField } from '../final-forms';
import { ITextFieldProps } from './types';
import { validatePrice } from '../validators';

export const DeliveryToCityPriceField: React.FC<ITextFieldProps> = (props) => (
  <TextField
    name="deliveryToCityPrice"
    label="Доставка авто в ваш город"
    placeholder="Пожалуйста, введите цену доставки авто в город клиента"
    type="number"
    validate={validatePrice}
    {...props}
  />
);
