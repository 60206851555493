import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '../Button/Button';

export interface IDialogConfirmProps extends Partial<DialogProps> {
  title: string
  isLoading?: boolean
  onSubmit?: () => void
  onClose?: () => void
}

export const DialogConfirm: React.FC<IDialogConfirmProps> = ({
  title,
  isLoading,
  children,
  open = false,
  onSubmit = () => {},
  onClose = () => {},
  ...restProps
}) => (
  <Dialog
    disableEscapeKeyDown={isLoading}
    disableBackdropClick={isLoading}
    open={open}
    onClose={onClose}
    {...restProps}
  >
    <DialogTitle>
      { title }
    </DialogTitle>
    { children && (
      <DialogContent>
        { children }
      </DialogContent>
    ) }
    <DialogActions>
      <Button
        color="default"
        disabled={isLoading}
        onClick={onClose}
      >
        Отмена
      </Button>
      <Button
        type="submit"
        isLoading={isLoading}
        onClick={onSubmit}
      >
        Сохранить
      </Button>
    </DialogActions>
  </Dialog>
);
